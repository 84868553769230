<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="事务所">
              <a-input v-model="searchData.name" placeholder="事务所名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="所属大区">
              <a-input v-model="searchData.area" placeholder="所属大区" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button type="primary" v-if="onAdd" @click="toHandler('add')">新增</a-button>
        <a-button type="primary" v-if="onEdit" @click="toHandler('edit')">修改</a-button>
        <a-button type="default" v-if="onDelete" @click="toHandler('del')">删除</a-button>
        <a-button type="default" v-if="selectedRowKeys.length >= 1 && resetPassword" @click="onPassword()"
          >重置密码</a-button
        >
        <downLoad method="get" api="/api/firm/mhiac/mhiacFirm/excelMhiacFirmList" :params="searchData" name="事务所资料.xls">导出事务所资料</downLoad>
      </div>
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
        :rowClassName="rowClassName"
      >
      </a-table>
      <!-- 编辑表单 -->
      <MhiacFirm-edit-modal ref="MhiacFirmEditModal" @reload="getData"></MhiacFirm-edit-modal>
      <Password ref="password"></Password>
    </div>
  </a-card>
</template>

<script>
import Password from './components/password'
import { columns } from './components/colums.js'
import MhiacFirmEditModal from './components/MhiacFirmEditModal.vue'
import { delMhiacFirm, listMhiacFirm, selectByIdMhiacFirm } from './api/MhiacFirmApi'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'mhiacFirm',
  components: {
    MhiacFirmEditModal,
    Password
  },
  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      onAdd: checkPermission('dealer:office:add'),
      onEdit: checkPermission('dealer:office:edit'),
      onDelete: checkPermission('dealer:office:delete'),
      resetPassword: checkPermission('dealer:office:resetPassword'),
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    onPassword() {
      const _this = this
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      _this.$refs.password.onsetRowData(_this.selectedRows[0])
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      listMhiacFirm({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1;
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page.current = 1;
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.MhiacFirmEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.MhiacFirmEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delMhiacFirm(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
</style>
